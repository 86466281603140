import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom'
import testimonialbg from '../../assets/images/backgrounds/testimonial-one-bg.jpg'
import testimonialico from '../../assets/images/icon/video-one-icon.png'
import client1 from '../../assets/images/testimonial/testimonial-1-1.jpg'
import client2 from '../../assets/images/testimonial/testimonial-1-2.jpg'


function TestimonialOne() {
  let satting = {
    loop: true,
    autoplay: true,
    margin: 30,
    // nav: false,
    // dots: false,
    smartspeed: 500,
    autoplaytimeout: 10000,
    navtext: ['<span className="icon-left-arrow"></span>","<span className="icon-right-arrow"></span>'],
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      },
      992: {
        items: 2
      },
      1200: {
        items: 3
      }
    }
  }

  return (
    <>
      <section className="testimonial-one">
        <div className="testimonial-one__bg" style={{ backgroundImage: "url("+(testimonialbg)+")" }} />
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <div className="testimonial-one__left">
                <div className="section-title text-left">
                  <div className="section-title__tagline-box">
                    <span className="section-title__tagline">our feedbacks</span>
                  </div>
                  <h2 className="section-title__title">
                    Clients are
                    <span>Talking</span>
                  </h2>
                </div>
                <div className="testimonial-one__rounded-text">
                  <Link to="/" className="testimonial-one__curved-circle-box" >
                    <div className="curved-circle">
                      <span className="curved-circle--item" style={{ position: "relative", height: "167.782px" }}>
                        <span className="char1" style={{ position: "absolute", left: "50%", marginLeft: "-0.5em", transform: "rotate(-171.454deg)", transformOrigin: "center 5.2272em" }} >
                          3
                        </span>
                        <span className="char2" style={{ position: "absolute", left: "50%", marginLeft: "-0.5em", transform: "rotate(-154.362deg)", transformOrigin: "center 5.2272em" }} >
                          8
                        </span>
                        <span className="char3" style={{ position: "absolute", left: "50%", marginLeft: "-0.53125em", transform: "rotate(-136.736deg)", transformOrigin: "center 5.2272em" }} >
                          0
                        </span>
                        <span className="char4" style={{ position: "absolute", left: "50%", marginLeft: "-0.34375em", transform: "rotate(-121.78deg)", transformOrigin: "center 5.2272em" }} >
                          &nbsp;
                        </span>
                        <span className="char5" style={{ position: "absolute", left: "50%", marginLeft: "-0.53125em", transform: "rotate(-106.825deg)", transformOrigin: "center 5.2272em" }} >
                          s
                        </span>
                        <span className="char6" style={{ position: "absolute", left: "50%", marginLeft: "-0.5625em", transform: "rotate(-88.6647deg)", transformOrigin: "center 5.2272em" }} >
                          a
                        </span>
                        <span className="char7" style={{ position: "absolute", left: "50%", marginLeft: "-0.5em", transform: "rotate(-71.0386deg)", transformOrigin: "center 5.2272em" }} >
                          t
                        </span>
                        <span className="char8" style={{ position: "absolute", left: "50%", marginLeft: "-0.46875em", transform: "rotate(-54.4807deg)", transformOrigin: "center 5.2272em" }} >
                          i
                        </span>
                        <span className="char9" style={{ position: "absolute", left: "50%", marginLeft: "-0.53125em", transform: "rotate(-37.3887deg)", transformOrigin: "center 5.2272em" }} >
                          s
                        </span>
                        <span className="char10" style={{ position: "absolute", left: "50%", marginLeft: "-0.5em", transform: "rotate(-19.7626deg)", transformOrigin: "center 5.2272em" }} >
                          f
                        </span>
                        <span className="char11" style={{ position: "absolute", left: "50%", marginLeft: "-0.46875em", transform: "rotate(-3.20475deg)", transformOrigin: "center 5.2272em" }} >
                          i
                        </span>
                        <span className="char12" style={{ position: "absolute", left: "50%", marginLeft: "-0.53125em", transform: "rotate(13.8872deg)", transformOrigin: "center 5.2272em" }} >
                          e
                        </span>
                        <span className="char13" style={{ position: "absolute", left: "50%", marginLeft: "-0.59375em", transform: "rotate(33.1157deg)", transformOrigin: "center 5.2272em" }} >
                          d
                        </span>
                        <span className="char14" style={{ position: "absolute", left: "50%", marginLeft: "-0.34375em", transform: "rotate(49.1395deg)", transformOrigin: "center 5.2272em" }} >
                          &nbsp;
                        </span>
                        <span className="char15" style={{ position: "absolute", left: "50%", marginLeft: "-0.53125em", transform: "rotate(64.095deg)", transformOrigin: "center 5.2272em" }} >
                          c
                        </span>
                        <span className="char16" style={{ position: "absolute", left: "50%", marginLeft: "-0.5em", transform: "rotate(81.7211deg)", transformOrigin: "center 5.2272em" }} >
                          l
                        </span>
                        <span className="char17" style={{ position: "absolute", left: "50%", marginLeft: "-0.46875em", transform: "rotate(98.2789deg)", transformOrigin: "center 5.2272em" }} >
                          i
                        </span>
                        <span className="char18" style={{ position: "absolute", left: "50%", marginLeft: "-0.53125em", transform: "rotate(115.371deg)", transformOrigin: "center 5.2272em" }} >
                          e
                        </span>
                        <span className="char19" style={{ position: "absolute", left: "50%", marginLeft: "-0.59375em", transform: "rotate(134.599deg)", transformOrigin: "center 5.2272em" }} >
                          n
                        </span>
                        <span className="char20" style={{ position: "absolute", left: "50%", marginLeft: "-0.5em", transform: "rotate(153.294deg)", transformOrigin: "center 5.2272em" }} >
                          t
                        </span>
                        <span className="char21" style={{ position: "absolute", left: "50%", marginLeft: "-0.53125em", transform: "rotate(170.92deg)", transformOrigin: "center 5.2272em" }} >
                          s
                        </span>
                      </span>
                    </div>

                    <div className="testimonial-one__icon">
                      <img src={testimonialico} alt="" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="testimonial-one__right">
                <Swiper {...satting} spaceBetween={30} slidesPerView={2} id="testimonial-one__carousel owl-theme thm-owl__carousel">
                  <SwiperSlide className="item">
                    <div className="testimonial-one__single">
                      <div className="testimonial-one__content">
                        <div className="testimonial-one__shape-1" />
                        <div className="testimonial-one__shape-2" />
                        <div className="testimonial-one__img">
                          <img src={client1} alt="" />
                        </div>
                        <div className="testimonial-one__ratting">
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                        </div>
                        <p className="testimonial-one__text">
                          Working with this team has been an absolute breeze, we have an ongoing project and 
                          they have been in every way very cooperative and time cautious. I would recommend 
                          working with them for whatever you may need.
                        </p>
                      </div>
                      <div className="testimonial-one__client-info">
                        <h3>
                          <Link to="/">Rostand CHENDJOU</Link>
                        </h3>
                        <p>Happy Client</p>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide className="item">
                    <div className="testimonial-one__single">
                      <div className="testimonial-one__content">
                        <div className="testimonial-one__shape-1" />
                        <div className="testimonial-one__shape-2" />
                        <div className="testimonial-one__img">
                          <img src={client2} alt="" />
                        </div>
                        <div className="testimonial-one__ratting">
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                        </div>
                        <p className="testimonial-one__text">
                          Worked with them on a several projects, and we are happy with the quality of work. 
                          They delivered everything on time and they are very responsive.<br />
                          <br/>
                          <br />
                        </p>
                      </div>
                      <div className="testimonial-one__client-info">
                        <h3>
                          <Link to="/">Jervis NNOKO</Link>
                        </h3>
                        <p>Happy Client</p>
                      </div>
                    </div>
                  </SwiperSlide>

                  {/*<SwiperSlide className="item">
                    <div className="testimonial-one__single">
                      <div className="testimonial-one__content">
                        <div className="testimonial-one__shape-1" />
                        <div className="testimonial-one__shape-2" />
                        <div className="testimonial-one__img">
                          <img src="assets/images/testimonial/testimonial-1-3.jpg" alt="" />
                        </div>
                        <div className="testimonial-one__ratting">
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                        </div>
                        <p className="testimonial-one__text">
                          Worked with them on a several projects, and we are happy with the quality of work. They delivered everything on time and they are very responsive.
                        </p>
                      </div>
                      <div className="testimonial-one__client-info">
                        <h3>
                          <Link to="/testimonials">Sarah Albert</Link>
                        </h3>
                        <p>Happy Client</p>
                      </div>
                    </div>
                  </SwiperSlide>*/}

                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TestimonialOne
