import React from 'react'
import { Link } from 'react-router-dom'
import NumberCounter from 'number-counter';
import counterbg from '../../assets/images/backgrounds/motto-bg.jpg'
import isoicon from '../../assets/images/content-icon/iso-icon.png'
import qteamicon from '../../assets/images/content-icon/qteam-icon.png'
import businessissueicon from '../../assets/images/content-icon/business-issue-icon.png'
import agileicon from '../../assets/images/content-icon/agile-icon.png'


function Motto() {
    return (
        <>
            <section className="motto">
                <div className="motto__inner">
                <div className="motto-one__shadow"></div>    
                    <div className="motto-one__bg" style={{ backgroundImage: 'url('+(counterbg)+')' }} />
                    <div className="container">
                        <div className="row">
                            <div className="section-title text-center">
                                <h2 className="section-title__title">
                                    We offer you the best quality service <span>including</span>
                                </h2>
                            </div>
                            <div className="motto__right">
                                    <ul className="motto__count-box list-unstyled">
                                        <li>
                                            <div className="motto-one__icon">
                                                <span><img src={isoicon} alt="" /></span>
                                            </div>
                                            <p className="motto-one__text">ISO 27001 Standard</p>
                                        </li>
                                        <li>
                                            <div className="motto-one__icon">
                                                <span><img src={qteamicon} alt="" /></span>
                                            </div>
                                            <p className="motto-one__text">Hight Qualified Teams</p>
                                        </li>
                                        <li>
                                            <div className="motto-one__icon">
                                                <span><img src={businessissueicon} alt="" /></span>
                                            </div>
                                            <p className="motto-one__text">Understanding Business Issues</p>
                                        </li>
                                        <li>
                                            <div className="motto-one__icon">
                                                <span><img src={agileicon} alt="" /></span>
                                            </div>
                                            <p className="motto-one__text">Agile ROI - Driven</p>
                                        </li>
                                    </ul>
                                </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Motto
