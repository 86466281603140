import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import camtrack from '../../assets/images/brand/brand-1-1.png';
import dhlf from '../../assets/images/brand/brand-1-2.png';
import normashop from '../../assets/images/brand/brand-1-3.png';
import { Link } from 'react-router-dom';


function BrandOne() {

  return (

    <>
      <section className="brand-one">
        <div className="brand-one__title">
          <h3>They Trust us</h3>
        </div>
        <div className="container">
          <Swiper spaceBetween={30} slidesPerView={3} navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }} loop={true}
          //  navigation 
          //  pagination={{ clickable: true }}
          //  autoplay={{ delay: 3000 }}
          //  loop={true}
          >
            <SwiperSlide className="brand-one__single">
              <div className="brand-one__img">
                <Link to={"https://camtrack.net/"}><img src={camtrack} alt="" /></Link>
              </div>
            </SwiperSlide>

            <SwiperSlide className="brand-one__single">
              <div className="brand-one__img">
                <Link to={"https://diamondhilllaw.com/"}><img src={dhlf} alt="" /></Link>
              </div>
            </SwiperSlide>

            <SwiperSlide className="brand-one__single">
              <div className="brand-one__img">
                <Link to={"https://web.facebook.com/normashopCM"}><img src={normashop} alt="" /></Link>
              </div>
            </SwiperSlide>

            {/*}SwiperSlide className="brand-one__single">
              <div className="brand-one__img">
                <img src="assets/images/brand/brand-1-4.png" alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="brand-one__single">
              <div className="brand-one__img">
                <img src="assets/images/brand/brand-1-5.png" alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="brand-one__single">
              <div className="brand-one__img">
                <img src="assets/images/brand/brand-1-5.png" alt="" />
              </div>
            </SwiperSlide>*/}
          </Swiper>
        </div>
      </section>

    </>
  )
}

export default BrandOne
