import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import bottonslider from '../../assets/images/shapes/main-slider-two-bottom-shape-2.png'
import VideoHeader from '../MainHomeOne/VideoHeader'

function Sllider() {
  let satting = {
    // loop: 1,
    loop: true,
    items: 1,
    margin: 0,
    // dots: false,
    // nav: 1,
    animateout: "fadeOut",
    animatein: "fadeIn",
    active: 1,
    smartspeed: 1000,
    autoplay: 1,
    autoplaytimeout: 7000,
    autoplayhoverpause: 0,
  };

  return (
    <>
      <section className="main-slider-two">
        <div className="main-slider-two__bottom-shape" style={{ backgroundImage: "url("+(bottonslider)+")" }} />
        <Swiper {...satting} className="main-slider__carousel owl-theme thm-owl__carousel">
          <SwiperSlide className="item main-slider-two__slide-1">
            <div className="main-slider-two__bg" style={{ backgroundImage: "url(assets/images/backgrounds/main-slider-2-11.png)" }}></div>
            <div className="main-slider-two__shadow" />
            <div className="main-slider-two__shape-1">
              <img src="assets/images/shapes/main-slider-two-shape-1.png" alt="" />
            </div>
            <div className="container">
              <div className="main-slider-two__content">
                <h2 className="main-slider-two__title">
                  Leaders In Data Analytics <br />Technology Design &amp; <br />Development In <br /><span>Central Africa</span>
                </h2>
                <p className="main-slider-two__text">
                  We offer a wide range of data analytics software and solutions designed
                  for <br /> companies looking to improve their performances.
                </p>
                <div className="main-slider-two__btn-box">
                  <Link to="/about" className="main-slider-two__btn thm-btn">
                    Discover More
                  </Link>
                </div>
                <div className="main-slider-two__rounded-text">
                  <Link to="#" className="main-slider-two__curved-circle-box">
                    {/*<div className="curved-circle">
                      <span className="curved-circle--item">
                        The best constulting
                      </span>
                    </div>*/}

                    <div className="main-slider-two__icon">
                      <VideoHeader />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  );
}

export default Sllider;
