import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom'

import testimonialshape1 from '../../assets/images/shapes/testimonial-two-shape-1.png'
import testimonialshape2 from '../../assets/images/shapes/testimonial-two-shape-2.png'
import testimonialshape3 from '../../assets/images/shapes/testimonial-two-shape-3.png'
import testimonialshape4 from '../../assets/images/shapes/testimonial-two-shape-4.png'
import rostand from '../../assets/images/testimonial/testimonial-2-1.jpg'
import jervis from '../../assets/images/testimonial/testimonial-2-2.jpg'


function Testimonial() {
  return (
    <>
      <section className="testimonial-two about-page-testimonial">
        <div className="testimonial-two__bg-box">
          <div className="testimonial-two__bg" style={{ backgroundImage: "url(assets/images/backgrounds/testimonial-two-bg.png)" }} />
        </div>
        <div className="container">
          <div className="section-title text-center">
            <div className="section-title__tagline-box">
              <span className="section-title__tagline">our testimonials</span>
            </div>
            <h2 className="section-title__title">
              What They’re Talking
              <br /> About <span>Zemtsi Data</span>
            </h2>
          </div>
          <div className="testimonial-two__bottom">
            <Swiper spaceBetween={30} slidesPerView={2} loop={false} >
              <SwiperSlide className="item">
                <div className="testimonial-two__single">
                  <div className="testimonial-two__shape-1">
                    <img src={testimonialshape1} alt="" />
                  </div>
                  <div className="testimonial-two__shape-2">
                    <img src={testimonialshape2} alt="" />
                  </div>
                  <div className="testimonial-two__shape-3">
                    <img src={testimonialshape3} alt="" />
                  </div>
                  <div className="testimonial-two__shape-4">
                    <img
                      src={testimonialshape4}
                      alt=""
                    />
                  </div>
                  <div className="testimonial-two__client-img-and-info">
                    <div className="testimonial-two__client-img">
                      <img
                        src={rostand}
                        alt=""
                      />
                    </div>
                    <div className="testimonial-two__client-info">
                      <h3>
                        <Link to="/about">Rostand CHENDJOU</Link>
                      </h3>
                      <p>Quality & ERP Director at CAMTRACK</p>
                    </div>
                  </div>
                  <p className="testimonial-two__text">
                    Working with this team has been an absolute breeze, we have an ongoing project and 
                    they have been in every way very cooperative and time cautious. I would recommend 
                    working with them for whatever you may need.
                  </p>
                </div>
              </SwiperSlide>

              <SwiperSlide className="item">
                <div className="testimonial-two__single">
                  <div className="testimonial-two__shape-1">
                    <img
                      src={testimonialshape1}
                      alt=""
                    />
                  </div>
                  <div className="testimonial-two__shape-2">
                    <img
                      src={testimonialshape2}
                      alt=""
                    />
                  </div>
                  <div className="testimonial-two__shape-3">
                    <img
                      src={testimonialshape3}
                      alt=""
                    />
                  </div>
                  <div className="testimonial-two__shape-4">
                    <img
                      src={testimonialshape4}
                      alt=""
                    />
                  </div>
                  <div className="testimonial-two__client-img-and-info">
                    <div className="testimonial-two__client-img">
                      <img
                        src={jervis}
                        alt=""
                      />
                    </div>
                    <div className="testimonial-two__client-info">
                      <h3>
                        <Link to="/about">Jervis NNOKO</Link>
                      </h3>
                      <p>Dimond Hill Law Firm Founder</p>
                    </div>
                  </div>
                  <p className="testimonial-two__text">
                    Worked with them on a several projects, and we are happy with the quality of work. 
                    They delivered everything on time and they are very responsive.<br />
                    <br />
                    <br />
                  </p>
                </div>
              </SwiperSlide>

              {/*<SwiperSlide className="item">
                <div className="testimonial-two__single">
                  <div className="testimonial-two__shape-1">
                    <img
                      src="assets/images/shapes/testimonial-two-shape-1.png"
                      alt=""
                    />
                  </div>
                  <div className="testimonial-two__shape-2">
                    <img
                      src="assets/images/shapes/testimonial-two-shape-2.png"
                      alt=""
                    />
                  </div>
                  <div className="testimonial-two__shape-3">
                    <img
                      src="assets/images/shapes/testimonial-two-shape-3.png"
                      alt=""
                    />
                  </div>
                  <div className="testimonial-two__shape-4">
                    <img
                      src="assets/images/shapes/testimonial-two-shape-4.png"
                      alt=""
                    />
                  </div>
                  <div className="testimonial-two__client-img-and-info">
                    <div className="testimonial-two__client-img">
                      <img
                        src="assets/images/testimonial/testimonial-2-3.jpg"
                        alt=""
                      />
                    </div>
                    <div className="testimonial-two__client-info">
                      <h3>
                        <Link to="/testimonials">Christine Eve</Link>
                      </h3>
                      <p>CO Founder</p>
                    </div>
                  </div>
                  <p className="testimonial-two__text">
                    Exercitation ullamco laboris nisi ut aliquip ex ea ex commodo
                    consequat duis aute aboris nisi ut aliquip irure reprehederit in
                    voluptate velit esse.
                  </p>
                </div>
              </SwiperSlide>

              <SwiperSlide className="item">
                <div className="testimonial-two__single">
                  <div className="testimonial-two__shape-1">
                    <img
                      src="assets/images/shapes/testimonial-two-shape-1.png"
                      alt=""
                    />
                  </div>
                  <div className="testimonial-two__shape-2">
                    <img
                      src="assets/images/shapes/testimonial-two-shape-2.png"
                      alt=""
                    />
                  </div>
                  <div className="testimonial-two__shape-3">
                    <img
                      src="assets/images/shapes/testimonial-two-shape-3.png"
                      alt=""
                    />
                  </div>
                  <div className="testimonial-two__shape-4">
                    <img
                      src="assets/images/shapes/testimonial-two-shape-4.png"
                      alt=""
                    />
                  </div>
                  <div className="testimonial-two__client-img-and-info">
                    <div className="testimonial-two__client-img">
                      <img
                        src="assets/images/testimonial/testimonial-2-4.jpg"
                        alt=""
                      />
                    </div>
                    <div className="testimonial-two__client-info">
                      <h3>
                        <Link to="/testimonials">Michale Robert</Link>
                      </h3>
                      <p>CO Founder</p>
                    </div>
                  </div>
                  <p className="testimonial-two__text">
                    Exercitation ullamco laboris nisi ut aliquip ex ea ex commodo
                    consequat duis aute aboris nisi ut aliquip irure reprehederit in
                    voluptate velit esse.
                  </p>
                </div>
              </SwiperSlide>*/}

            </Swiper>
          </div>
        </div>
      </section>
    </>
  )
}

export default Testimonial
